<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<!-- 代理等级 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" @keyup.enter.native="goodsSearch" placeholder="分组名称" style="width: 200px;;margin-right: 10px;"
				 clearable></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:25px;width:90px;" @click="goodsSearch">查询</el-button>
				<buttonPermissions :datas="'addSort'">
					<el-button type="primary" style="margin-left:25px;width:90px;" @click="eidtGroup({})">添加</el-button>
				</buttonPermissions>

				<el-button style="margin-left:25px;width:90px;" @click="groupExport">导出</el-button>
			</div>

			<div class="filter-item exportBtn">
				<form name="uploadForm" method="post" style="display: inline-block;" class="exportInput">
					<input type="file" ref="upFiles" id="inputsId" @change="inputChange">
				</form>
				<buttonPermissions :datas="'importSort'">
					<el-button style="width:90px;">导入</el-button>
				</buttonPermissions>
			</div>

		</div>

		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" v-loading="loading" @sort-change="sortChange">
				<el-table-column prop="GroupName" label="分组名称"></el-table-column>
				<el-table-column label="分组商品">
					<template slot-scope="scope">
						<div v-if="scope.row.ProductCount==0">{{scope.row.ProductCount}}</div>
						<div v-else style="color: #409EFF;cursor:pointer" @click="jumpToShop(scope.row)">{{scope.row.ProductCount}}</div>
					</template>
				</el-table-column>
				
				<el-table-column label="列表隐藏">
					<template slot-scope="scope">
						<div>{{scope.row.IsHiddenStr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="序号" sortable prop="SortAsc">
					<template slot-scope="scope">
						<div>
							<span style="margin-right: 10px;">{{scope.row.SortAsc}}</span>
							<el-popover placement="right" width="230" v-model="scope.row.Numbervisible">
								<div style="display: flex;">
									<el-input v-model="scope.row.SortAsc" style="width: 100px;margin-right: 10px;" @input='OnlyNumber(scope.row.SortAsc,scope.$index)'></el-input>
									<el-button type='text' @click="SaveNumber(scope.row,scope.$index)">保存</el-button>
									<el-button type='text' @click="scope.row.Numbervisible = false;scope.row.SortAsc=0">取消</el-button>
								</div>

								<i class="el-icon-edit" slot="reference"></i>
							</el-popover>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="180">
					<template slot-scope="scope">
						<buttonPermissions :datas="'reviseSort'" class="margin-right-10">
							<el-button type="text" @click="eidtGroup(scope.row)">编辑</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'setsalesman'" class="margin-right-10">
							<el-button type="text" @click="setsalesman(scope.row)">设置销售员</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'deleteSort'">
							<el-button type="text" style="color:#f00" @click="deleteChose(scope.row)">删除</el-button>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<!-- 商品名称编辑的弹框 -->
		<el-dialog :visible.sync="editVisable" width="50%" title="添加分组" :show-close="false">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="分组名称:" prop="name">
						<el-input v-model="ruleForm.name" placeholder="最多输入16个字" maxlength="16" clearable style="width:300px"></el-input>
					</el-form-item>
					<el-form-item label="列表隐藏:" prop="IsHidden">
						<el-radio-group v-model="ruleForm.IsHidden">
							<el-radio :label="true">是</el-radio>
							<el-radio :label="false">否</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				<!-- <label class="label">分组名称: </label>
				<el-input v-model="groupName" placeholder="最多输入16个汉字" maxlength="16" clearable style="flex:1">
				</el-input> -->
			</div>

			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="editVisable=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange('ruleForm')" :loading="loading">保存</el-button>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="setShow" title="设置销售员" width="600px" @closed="clearPhone">
			<div class="flex flex-align-start">
				<div class="keyLabel" style="line-height:36px;width:100px;text-align:right">设置销售员：</div>
				<div>
					<el-input v-model.trim="clientMessage.Phone" style="width:300px" placeholder="输入员工手机号，点击右侧[查询]" clearable
					 @clear="clearPhone"></el-input>
					<div class="referrer-card" v-if="clientMessage.EmployeeId">
						<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
							<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
						</div>
						<img :src='clientMessage.WxHeadUrl' v-if="clientMessage.WxHeadUrl" style="width: 50px; height: 50px;border-radius: 100%;" />
						<img v-else src="https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png" alt="" style="width: 50px; height: 50px;border-radius: 100%;">
						<div class="referrer-text">
							<div class="name">{{clientMessage.EmployeeShowName||'--'}}</div>
							<div class="name">{{clientMessage.ShopName||'--'}}</div>
						</div>
					</div>
				</div>
				<el-button type="primary" class="margin-left-10" @click="searchsalesman">查询</el-button>
			</div>
			<div class="flex flex-align-center margin-top-20">
				<div class="keyLabel" style="line-height:36px;width:100px;text-align:right">对外展示名称：</div>
				<el-input v-model="clientMessage.EmployeeName" style="width:300px" maxlength="20" placeholder="不填写，默认使用员工姓名"></el-input>
			</div>
			<div class="flex margin-top-20">
				<div class="keyLabel" style="line-height:36px;width:100px;text-align:right">销售员二维码：</div>
				<el-upload :action="imgApi" accept="image/*" list-type="picture-card" :show-file-list="false"
					:before-upload="beforeupload" :on-success="($event)=>{onsuccess($event,'EmployeeQrCode')}">
					<div style="width:100%;overflow-y:hidden;height:100%" v-if="clientMessage.EmployeeQrCode">
						<img :src="imgUrl+clientMessage.EmployeeQrCode" style="width:100%" alt="">
					</div>
					<i class="el-icon-plus" v-else></i>
				</el-upload>
			</div>
			<div slot="footer">
				<el-button @click="setShow=false,clearPhone">取消</el-button>
				<el-button type="primary" @click="saveset">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		productGroupListData,
		productGroupSave,
		productGroupDelete
	} from '@/api/goods'
	import {
		productGroupupdateSort,
		productGroupproductGroupEmployeeSave,
		productGroupproductGroupEmployeeInfo
	} from '@/api/TurnTomySelf'
	import{
		employeeemployeeinfobyphone
	} from "@/api/sv3.0.0"
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			var checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入分组名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入分组名称'));
				} else {
					return callback()
				}
			};
			return {
				ruleForm: {
					name: '',
					IsHidden :false,
					SortAsc:0
				},
				rules: {
					name: [{
							required: true,
							// message: '请输入分组名称',
							trigger: 'blur',
							validator: checkName
						},
						{
							min: 1,
							max: 16,
							message: '最多输入16个字',
							trigger: 'blur'
						}
					]
				},
				groupData: [],
				searchKey: '',
				loading: false,
				editVisable: false,
				groupName: "",
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				eidtGroupData: {},
				exportUrl: config.EXPORT_URL,
				imgApi:config.UPLOAD_IMG,
				imgUrl:config.IMG_BASE,
				OrderBy: '',
				IsAsc: '',
				rowmsg:{},
				setShow:false,
				clientMessage:{
					EmployeeId:'',
					Phone:'',
					EmployeeName:'',
					EmployeeQrCode:'',
					ShopName:'',
					WxHeadUrl:'',
					EmployeeShowName:''
				},
			};
		},
		beforeMount() {
			this.getList()
		},
		methods: {
			async saveset(){
				if(this.clientMessage.Phone&&!this.clientMessage.EmployeeId){
					this.$message.error('请输入手机号，查询并添加员工为销售员')
					return
				}
				try{

					let res = await productGroupproductGroupEmployeeSave({
						ProductGroupId:this.rowmsg.Id,
						EmployeeId:this.clientMessage.EmployeeId,
						EmployeeName:this.clientMessage.EmployeeName,
						EmployeeQrCode:this.clientMessage.EmployeeQrCode,
					})
					if(res.IsSuccess){
						this.$message.success('保存成功')
						this.setShow = false
						this.getList()
					}
				}finally{
					//
				}
			},
			clearPhone(){
				for(let key in this.clientMessage){
					this.clientMessage[key] = ''
				}
			},
			onsuccess(file,key){
				this.clientMessage[key] = file[0]
			},
			beforeupload(file){
				const size = file.size / 1024 / 1024 < 1;
				const testtype = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg']
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('图片大小请控制在1M以内');
					return false
				}
			},
			async searchsalesman(){
				if(!this.clientMessage.Phone){
					this.$message.error('请输入手机号')
					return
				}
				if(this.clientMessage.Phone.length!=11){
					this.$message.error('请输入完整的11位手机号')
					return
				}
				try{
					let res = await employeeemployeeinfobyphone({
						Phone:this.clientMessage.Phone
					})
					if(res.IsSuccess){
						if(res.Result.Id==0){
							this.$message.error('未找到对应员工数据，请检查手机号是否有误')
							return
						}
						// console.log(res.Result)
						this.clientMessage.EmployeeId = res.Result.Id
						this.clientMessage.EmployeeName = res.Result.EmployeeName
						this.clientMessage.WxHeadUrl = res.Result.WxHeadUrl
						this.clientMessage.ShopName = res.Result.ShopName
						this.clientMessage.EmployeeShowName = res.Result.EmployeeName
					}
				}finally{
					//
				}
			},
			setsalesman(row){
				this.rowmsg = row
				this.setShow = true
				this.getsalesman()
			},
			async getsalesman(){
				try{
					let res = await productGroupproductGroupEmployeeInfo({
						ProductGroupId:this.rowmsg.Id,
					})
					if(res.IsSuccess){
						// console.log(res.Result)
						this.clientMessage.EmployeeId = res.Result.EmployeeId||''
						this.clientMessage.EmployeeName = res.Result.EmployeeName||''
						this.clientMessage.EmployeeQrCode = res.Result.EmployeeQrCode||''
						this.clientMessage.ShopName = res.Result.ShopName||''
						this.clientMessage.WxHeadUrl = res.Result.WxHeadUrl||''
						this.clientMessage.Phone = res.Result.Phone||''
						this.clientMessage.EmployeeShowName = res.Result.EmployeeShowName||''
					}
				}finally{
					//
				}
			},
			// 序号排序
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				this.getList();
			},
			//保存序号
			async SaveNumber(record,index){
				try{
					if(record.SortAsc > 99999){
						this.$message({
							showClose: true,
							type: 'error',
							message: '序号值请设置在0~99999之间，整数!'
						});
						return
					}
					this.loading  = true
					let data = {
						Id : record.Id,
						SortAsc : record.SortAsc 
					}
					let result = await productGroupupdateSort(data)
					if(result.IsSuccess){
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.groupData[index].Numbervisible = false
						this.getList()
						
					}
				}catch(err){
					
				}finally{
					this.loading  = false
				}
			},
			// CancelSortAsc(index){
			// 	this.groupData[index].Numbervisible = false
			// 	// this.$forceUpdate()
			// 	console.log(this.groupData[index].Numbervisible,'9999999')
			// 	// this.$forceUpdate()
			// 	// console.log(this.groupData,'zen么回事儿')
			// 	// this.groupData[index].Numbervisible = false
			// 	// console.log(index,'点击的是哪一个')
			// },
			//限制输入
			OnlyNumber(obj, index) {
				obj = obj.replace(/[^\.\d]/g, '');
				obj = obj.replace('.', '');
				
				console.log(obj,'强制改变')
				this.groupData[index].SortAsc = obj
			},
			// 导出
			groupExport(record) {
				let url = this.exportUrl + '/pc/productGroup/export?' +
					'&KeyWord=' + this.searchKey
				console.log(url)
				window.open(url)
			},
			// 上传事件
			inputChange() {
				if (this.$refs['upFiles'].files[0]) {
					this.groupEnter()
				}
			},

			// 导入
			groupEnter() {
				let that = this;
				console.log('执行了吗')
				const loading = that.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					// background: 'rgba(0, 0, 0, 0.7)'
				});
				try {
					setTimeout(() => {
						var formData = new FormData();
						formData.append('file', that.$refs['upFiles'].files[0])
						$.ajax({
							url: this.exportUrl + '/pc/productGroup/import',
							timeout: 20000, //超时时间设置，单位毫秒
							type: 'POST',
							data: formData,
							async: false,
							cache: false,
							contentType: false,
							xhrFields: {
								withCredentials: true
							},
							processData: false,
							success: function(returndata) {
								console.log(returndata, '成功的结果')
								if (returndata.IsSuccess) {
									that.$message({
										message: '上传成功',
										type: 'success'
									});
									loading.close();
								} else {
									loading.close();
									that.$message({
										message: returndata.Message,
										type: 'error'
									});
								}
							},
							complete: function(XMLHttpRequest, status) { //请求完成后最终执行参数
								if (status == 'timeout') { //超时,status还有success,error等值的情况
									ajaxTimeoutTest.abort();
									alert("超时");
								}
							},
							error: function(returndata) {
								console.log(returndata, '失败的结果')
								loading.close();
								that.$message({
									message: returndata.Message,
									type: 'error'
								});
								// alert("文件上传失败");
							}
						});
					}, 500)

				} catch (error) {
					loading.close();
					console.log(error);
				} finally {
					that.getList()
				}
			},

			jumpToShop(val) {
				console.log('group',val)
				this.$router.push({
					path: '/goods/goodsList',
					query: {
						ProductGroupId: [val.Id]
					}
				})
			},
			// 获取列表数据
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWord: this.searchKey, // 搜索关键字
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc
					}
					let result = await productGroupListData(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			goodsSearch() {
				this.currentPage = 1;
				this.getList();
			},
			eidtGroup(item) {
				this.eidtGroupData = item;
				this.ruleForm.name = item.GroupName ? item.GroupName : ''
				this.ruleForm.IsHidden = item.IsHidden
				this.ruleForm.SortAsc = item.SortAsc
				this.editVisable = true
			},
			deleteChose(record) {
				this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {

					let result = await productGroupDelete({
						Id: record.Id
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					this.currentPage = 1;
					setTimeout(() => {
						this.goodsSearch();
					}, 500)

				})


			},
			async saveChange(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.loading = true
							let data = {
								Id: this.eidtGroupData.Id ? this.eidtGroupData.Id : 0,
								GroupName: this.ruleForm.name,
								IsHidden:this.ruleForm.IsHidden,
								SortAsc:this.ruleForm.SortAsc
							}
							let result = await productGroupSave(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.editVisable = false;
							}

						} catch (e) {
							console.log(e)
						} finally {
							this.goodsSearch();
							setTimeout(() => {
								this.loading = false
							}, 500)

						}
					} else {
						return false;
					}
				})


			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.referrer-card {
		img {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			object-fit: contain;
			margin-right: 10px;
		}

		width: 260px;
		height: 60px;
		border: 1px solid #67C23A;
		margin-top: 10px;
		border-radius: 1px;
		position: relative;
		padding: 5px;
		display: flex;
		flex-direction: row;
		line-height: 20px;
		align-items: center;

		.check {
			position: absolute;
			right: -1px;
			top: -4px;
		}

		::v-deep .el-checkbox {
			width: 14px;
			height: 14px;
		}

		::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #67C23A;
			border-color: #67C23A;
		}

		::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
			border-color: #67C23A;
		}

		::v-deep .el-checkbox__inner:hover {
			border-color: #67C23A;
		}

		.referrer-text {
			display: inline-block;
			width: 200px;
			overflow: hidden;
		}

		.name {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

		}
	}
	.exportBtn {
		position: relative;
	}

	.exportInput {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 90px;
	}

	#inputsId {
		display: inline-block;
		height: 35px;
		width: 90px;
	}
</style>
